// form
import { useFormContext, useController } from 'react-hook-form'
// components
import NumberField from '~/components/NumberField'

// ----------------------------------------------------------------------

/**
 * React Hook Form wrapper for TextField
 * @param {Object} props
 * @param {string} props.name
 * @param {React.ReactNode} [props.helperText]
 * @returns {JSX.Element}
 */
export default function RHFNumber({
  name,
  helperText,
  // intentionally added to props so input type will always be text
  type: _type,
  ...other
}) {
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  return (
    <NumberField
      {...field}
      // force input type to text since type number will have unwanted behavior on some browser
      type='text'
      fullWidth
      InputLabelProps={other.type === 'date' ? { shrink: true } : undefined}
      error={Boolean(error)}
      helperText={error?.message || helperText}
      {...other}
    />
  )
}
