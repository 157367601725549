// @mui
import { TextField } from '@mui/material'
// utils
import { formatNumber, reverseFormatNumber } from '~/libs/numberFormatter'
import { isNil } from 'lodash'
import { forwardRef } from 'react'

// ----------------------------------------------------------------------

/**
 * @param {import('@mui/material').TextFieldProps} props
 * @returns {JSX.Element}
 */
const NumberField = forwardRef(({ value, onChange, ...rest }, ref) => {
  const appliedValue =
    isFinite(value) && value !== '' && !isNil(value)
      ? formatNumber(value)
      : value

  const appliedOnchange = (e) => {
    // reverse format the number
    // i.e. 1.000.000,00 -> 1000000.00
    // note in the reversed format, the decimal separator is always '.'
    // since thats how you write a decimal number in javascript
    const parsedNumber = reverseFormatNumber(e.target.value)
    if (!parsedNumber) {
      onChange(e)

      return
    }

    onChange({
      ...e,
      target: { ...e.target, value: parsedNumber }
    })
  }

  return (
    <TextField
      ref={ref}
      {...rest}
      value={appliedValue}
      onChange={appliedOnchange}
    />
  )
})

NumberField.displayName = 'NumberField'

export default NumberField
