// react
import { useEffect, useState } from 'react'
// @mui
import { LoadingButton } from '@mui/lab'
// hooks
import { useSnackbar } from 'notistack'
import useAuth from '~/hooks/useAuth'
// services
import { fileService } from '~/services'
// utils
import browserHelper from '~/libs/browserHelper'

// ----------------------------------------------------------------------

const SIGNED_URL_EXPIRY = 60000 // in ms

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.fileId
 * @param {'Author'|'ContentPage'} [props.contextType='Author']
 * @param {string} [props.contextId='$user._id']
 * @returns {JSX.Element}
 */
export default function AccessFileButton({
  fileId,
  contextType = 'Author',
  contextId = '$user._id',
  ...rest
}) {
  const { isAuthenticated, authorizeService } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const [previousSignedURL, setPreviousSignedURL] = useState(null)

  // clear previousSignedURL after SIGNED_URL_EXPIRY
  useEffect(() => {
    if (!previousSignedURL)
      return () => {
        // intentionally left blank since no cleanup is needed
        // when previousSignedURL is not available
      }

    const timeout = setTimeout(() => {
      setPreviousSignedURL(null)
    }, SIGNED_URL_EXPIRY)

    return () => {
      clearTimeout(timeout)
    }
  }, [previousSignedURL])

  const handleAccessFile = async () => {
    setIsLoading(true)

    // if previousSignedURL is available, use it
    if (previousSignedURL) {
      browserHelper.openInNewTab(previousSignedURL)
      setIsLoading(false)

      return
    }

    try {
      const signedURL = await authorizeService(fileService.accessFile)(fileId, {
        contextType,
        contextId
      }).then((res) => res.data.results.signedURL)

      if (signedURL) {
        setPreviousSignedURL(signedURL)
        browserHelper.openInNewTab(signedURL)
      } else {
        enqueueSnackbar('File not found', { variant: 'error' })
      }
    } catch (_) {
      enqueueSnackbar('File access failed', { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoadingButton
      onClick={handleAccessFile}
      loading={isLoading}
      disabled={!isAuthenticated || rest?.disabled}
      {...rest}
    />
  )
}
