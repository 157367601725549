// react
import { useState, useMemo } from 'react'
// @mui
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Slider,
  Stack,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  PauseCircleOutlined,
  PlayCircleOutline,
  ReplayOutlined
} from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'
// hooks
import { useFile } from '~/swr/file'
// utils
import { Duration } from 'luxon'

// ----------------------------------------------------------------------

const SEEK_DURATION = 10 // seconds

const ControlButton = styled(IconButton)({
  padding: 0
})

const formatAudioTime = (seconds) => {
  return Duration.fromMillis(seconds * 1000).toFormat('mm:ss')
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.fileId
 * @param {string} props.accessContext
 * @param {string} props.accessContext.contextType
 * @param {string} props.accessContext.contextId
 * @param {boolean} props.autoPlay
 * @returns {JSX.Element}
 */
export default function AudioPlayer({
  fileId,
  accessContext,
  autoPlay = false
}) {
  const { data, isLoading } = useFile(
    ...(fileId ? [fileId, accessContext] : [null])
  )
  const isAudioReady = useMemo(
    () => Boolean(data?.status === 'ready' && data?.signedURL),
    [data]
  )

  // audio ref
  const [audioElmRef, setAudioElmRef] = useState(null)

  // audio time information
  const [audioCurrentTime, setAudioCurrentTime] = useState(0)
  const [audioDuration, setAudioDuration] = useState(0)

  const handleLoadedMetadata = (e) => {
    setAudioDuration(e.target.duration)
  }

  const handleTimeUpdate = (e) => {
    setAudioCurrentTime(e.target.currentTime)
  }

  // audio playback state
  const [isPlaying, setIsPlaying] = useState(false)

  // audio playback control
  const handlePlayPauseToggle = () => {
    if (!isAudioReady || !audioElmRef) return

    if (isPlaying) {
      audioElmRef.pause()
    } else {
      audioElmRef.play()
    }
  }

  const handlePlay = () => {
    if (!audioElmRef) return

    setIsPlaying(true)
  }

  const handlePauseOrEnded = () => {
    if (!audioElmRef) return

    setIsPlaying(false)
  }

  // audio slider event handling
  const handleSkip = () => {
    if (!audioElmRef) return

    // adjusted +1s to account for processing delay
    audioElmRef.currentTime += SEEK_DURATION - 1
  }

  const handleReplay = () => {
    if (!audioElmRef) return

    // adjusted -2s to account for processing delay
    audioElmRef.currentTime -= SEEK_DURATION + 2
  }

  // trackValue
  const handleAudioSeek = (_, newValue) => {
    if (!audioElmRef) return

    audioElmRef.currentTime = newValue
  }

  const currentTimeText = formatAudioTime(audioCurrentTime)
  const durationText = formatAudioTime(audioDuration)

  return (
    <Card>
      <Box padding={1.5}>
        <Slider
          aria-label='audio current time'
          size='small'
          value={audioCurrentTime}
          onChange={handleAudioSeek}
          step={1}
          min={0}
          max={audioDuration}
          valueLabelDisplay='off'
          sx={{ width: '100%', paddingBottom: 0 }}
        />

        <Stack
          spacing={0.25}
          direction='row'
          alignItems='center'
        >
          <ControlButton
            disabled={!isAudioReady}
            aria-label='replay 10 seconds'
            onClick={handleReplay}
          >
            <ReplayOutlined />
          </ControlButton>

          <ControlButton
            disabled={!isLoading && !isAudioReady}
            aria-label={isPlaying ? 'pause' : 'play'}
            onClick={handlePlayPauseToggle}
          >
            {isLoading ? (
              <CircularProgress
                size='1.5rem'
                color='inherit'
              />
            ) : isPlaying ? (
              <PauseCircleOutlined fontSize='2rem' />
            ) : (
              <PlayCircleOutline fontSize='2rem' />
            )}
          </ControlButton>

          <ControlButton
            disabled={!isAudioReady}
            aria-label='skip 10 seconds'
            onClick={handleSkip}
          >
            <ReplayOutlined sx={{ transform: 'scaleX(-1)' }} />
          </ControlButton>

          {data && data?.status !== 'ready' && (
            <Typography
              flexGrow={1}
              textAlign='right'
              fontSize='0.6rem'
              color='text.secondary'
            >
              Audio is still being processed, please check again in a few
              minutes!
            </Typography>
          )}

          {data?.status === 'ready' && data?.signedURL && (
            <Typography
              flexGrow={1}
              textAlign='right'
              fontSize='small'
            >
              {currentTimeText} / {durationText}
            </Typography>
          )}
        </Stack>
      </Box>

      <Box sx={visuallyHidden}>
        {data?.signedURL && (
          <audio
            // important, we use key props here to make sure the audio element is fully re-rendered
            // every time the fileId changes, this is to prevent unexpected behavior
            // from the audio element internal state
            key={fileId}
            ref={(ref) => setAudioElmRef(ref)}
            preload='metadata'
            src={data?.signedURL}
            onLoadedMetadata={handleLoadedMetadata}
            onDurationChange={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate}
            onPlay={handlePlay}
            onPause={handlePauseOrEnded}
            onEnded={handlePauseOrEnded}
            autoPlay={autoPlay}
          />
        )}
      </Box>
    </Card>
  )
}
