// react
import { useState } from 'react'
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
// components
import VideoPlayer from '~/components/VideoPlayer'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.fileId
 * @param {Object} props.buttonProps
 * @returns {JSX.Element}
 */
export default function VideoPreview({ fileId, buttonProps = {} }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const handlePreviewOpen = () => {
    setIsPreviewOpen(true)
  }

  const handlePreviewClose = () => {
    setIsPreviewOpen(false)
  }

  return (
    <>
      <Button
        onClick={handlePreviewOpen}
        disabled={!fileId}
        {...buttonProps}
      >
        Preview Video
      </Button>

      <Dialog
        open={isPreviewOpen}
        onClose={handlePreviewClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Video Preview</DialogTitle>

        <DialogContent>
          <VideoPlayer
            fileId={fileId}
            accessContext={{
              contextType: 'Author',
              contextId: '$user._id'
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={handlePreviewClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
