/**
 * @param {File} file
 * @returns {Promise<string>}
 */
const fileInputToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = reject
  })

const fileHelper = {
  fileInputToBase64
}

export default fileHelper
