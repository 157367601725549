// react
import { useEffect, useMemo, useState } from 'react'
// @mui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
// hooks
import { useFileList } from '~/swr/file'
import usePagination from '~/hooks/usePagination'
// utils
import { DateTime } from 'luxon'
// lodash
import { debounce } from 'lodash'

// subcomponents
import FileCard from './FileCard'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {'file'|'audio'} [props.mode='file'] - 'file' or 'audio'
 * @param {boolean} props.isOpen
 * @param {()=>void} props.onClose
 * @param {Object} props.currentFileId value from FileUpload.value
 * @param {(file:Object)=>void} props.onFileSelect
 * @returns {JSX.Element}
 */
export default function SelectFileModal({
  mode = 'file',
  isOpen,
  currentFileId,
  onClose,
  onFileSelect
}) {
  // pagination
  const { pageSize, startAfter, onNextPage, onPreviousPage } = usePagination({
    defaultRowsPerPage: 10
  })

  const { _ } = useLingui()

  // file select handler
  const createFileSelectHandler = (file) => () => {
    onFileSelect(file)
  }

  // search handler
  const [searchInput, setSearchInput] = useState('')
  const handleSearchInputChange = (e) => {
    const value = e.target.value

    setSearchInput(value)
  }

  const [searchValue, setSearchValue] = useState(null)
  const deboucedSetSearchValueState = useMemo(() => {
    return debounce((sv) => {
      setSearchValue(sv || null)
    }, 1000)
  }, [])

  useEffect(() => {
    deboucedSetSearchValueState(searchInput)
  }, [deboucedSetSearchValueState, searchInput])

  // data fetching
  const { data, isLoading } = useFileList(
    !isOpen
      ? null
      : {
          type: mode,
          search: searchValue,
          author: '$user._id',
          status: 'ready',
          startAfter,
          pageSize
        }
  )

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>{_(msg`Pilih file yang sudah ada`)}</DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <div>
            <TextField
              size='small'
              fullWidth
              label='Search File'
              onChange={handleSearchInputChange}
              value={searchInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchOutlined />
                  </InputAdornment>
                )
              }}
            />
          </div>

          <div>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 3,
                  height: '3rem'
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid
                container
                spacing={1.5}
              >
                {data?.map((file) => (
                  <Grid
                    key={file._id}
                    item
                    xs={12}
                    sm={6}
                  >
                    <FileCard
                      fileName={file.orginalFileName ?? `#${file._id}`}
                      createdAt={DateTime.now().toISO()}
                      isSelected={currentFileId === file._id}
                      onSelect={createFileSelectHandler(file)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>

          <div>
            <Grid
              container
              spacing={1.5}
              justifyContent='center'
            >
              <Grid
                item
                xs='auto'
              >
                <Button
                  size='small'
                  variant='outlined'
                  disabled={!startAfter}
                  onClick={onPreviousPage}
                >
                  {_(msg`Sebelumnya`)}
                </Button>
              </Grid>

              <Grid
                item
                xs='auto'
              >
                <Button
                  size='small'
                  variant='outlined'
                  disabled={data?.length < pageSize}
                  onClick={onNextPage(data?.[data?.length - 1]?._id)}
                >
                  {_(msg`Selanjutnya`)}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}
        >
          {_(msg`Tutup`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
