// react
import { useEffect, useRef } from 'react'
// form
import { useFormContext, useController } from 'react-hook-form'
// @mui
import { Box, FormHelperText } from '@mui/material'
// components
import Editor from '../Editor'

// ----------------------------------------------------------------------

/**
 * React Hook Form wrapper for TextField
 * @param {Object} props
 * @param {string} props.name
 * @param {React.ReactNode} [props.helperText]
 * @returns {JSX.Element}
 */
export default function RHFEditor({ name, helperText, ...other }) {
  const ref = useRef(null)

  const {
    control,
    formState: { defaultValues }
  } = useFormContext()

  // only pass value to Editor when defaultValues changed
  // i.e. when form init and reset
  useEffect(() => {
    ref.current?.setMarkdown(defaultValues[name])
  }, [defaultValues, name])

  const {
    field: { onChange, onBlur },
    fieldState: { error }
  } = useController({ name, control })

  return (
    <div>
      <Editor
        ref={ref}
        onBlur={onBlur}
        onChange={(value) => onChange(value)}
        initialValue={defaultValues[name]}
        {...other}
      />

      {(Boolean(error) || Boolean(helperText)) && (
        <Box marginTop={1}>
          <FormHelperText error={Boolean(error)}>
            {error.message || helperText}
          </FormHelperText>
        </Box>
      )}
    </div>
  )
}
