import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

// ----------------------------------------------------------------------

/**
 * @param {Object} params
 * @param {string} params.defaultOrder
 * @param {string} params.defaultOrderBy
 * @param {number} [params.defaultPageSize=10]
 * @returns {Object}
 */
export default function usePagination(params) {
  const { defaultOrder, defaultOrderBy, defaultPageSize = 10 } = params || {}

  const { isReady, query, push } = useRouter()
  const [isInitialized, setIsInitialized] = useState(false)
  const [page, setPage] = useState(0)
  const [startAfterStack, setStartAfterStack] = useState([])

  const {
    order = defaultOrder,
    orderBy = defaultOrderBy,
    pageSize = defaultPageSize,
    startAfter = null
  } = query

  const onOrderChange = (newOrder) => {
    push({ query: { ...query, order: newOrder } })
  }

  const onOrderByChange = (newOrderBy) => {
    push({ query: { ...query, orderBy: newOrderBy } })
  }

  // in case there's already start after when this first loaded
  // assume we're in page 1, since there's 1 previouse page possible
  // by clearing the startAfter
  useEffect(() => {
    if (!isInitialized) {
      if (startAfter && page === 0) setPage(1)

      setIsInitialized(true)
    }
  }, [isReady, startAfter, page, isInitialized])

  const onPreviousPage = () => {
    // if there's startAfter id in stack,
    // pop it, then use it for startAfter
    if (startAfterStack.length > 0) {
      const newStartAfter = startAfterStack[startAfterStack.length - 1]

      setPage(page - 1)
      setStartAfterStack(startAfterStack.slice(0, -1))

      push({ query: { ...query, startAfter: newStartAfter } })
    } else {
      // if there's  no startAfter id in stack,
      // clear startAfter, and setPage to 0
      const { startAfter: existingStartAfter, ...rest } = query

      setPage(0)

      push({ query: rest })
    }
  }

  const onNextPage = (nextStartAfter) => () => {
    // if nextStartAfter is defined,then
    // push current startAfter to stack
    // and use nextStartAfter as startAfter
    if (nextStartAfter) {
      setPage(page + 1)

      if (startAfter) {
        setStartAfterStack([...startAfterStack, startAfter])
      }

      push({ query: { ...query, startAfter: nextStartAfter } })
    }
  }

  const onPageChange = (nextStartAfter) => (event, newPage) => {
    //  move previous page
    if (newPage < page) {
      onPreviousPage()
    } else {
      onNextPage(nextStartAfter)()
    }
  }

  const onPageSizeChange = (event) => {
    push({
      query: {
        ...query,
        pageSize: parseInt(event?.target?.value ?? defaultPageSize)
      }
    })
  }

  return {
    isLoading: !isReady,
    // if orderBy is set and order is undefined, give default order of asc
    order: orderBy && order ? order : orderBy && !order ? 'asc' : undefined,
    orderBy,
    page,
    pageSize: parseInt(pageSize),
    startAfter,
    //
    onOrderChange,
    onOrderByChange,
    onPageChange,
    onPreviousPage,
    onNextPage,
    onPageSizeChange
  }
}
