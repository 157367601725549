// form
import { useFormContext, useController } from 'react-hook-form'
// components
import FileUpload from '~/components/FileUpload'

/**
 * React Hook Form wrapper for CloudflareImageUpload
 * @param {Object} props
 * @param {string} props.name
 * @returns {JSX.Element}
 */
export default function RHFFileUpload({ name, ...rest }) {
  const { control } = useFormContext()
  const {
    field: { ref, onChange, value },
    fieldState: { error }
  } = useController({ name, control })

  return (
    <div ref={ref}>
      <FileUpload
        onChange={onChange}
        value={value}
        error={error?.message}
        {...rest}
      />
    </div>
  )
}
