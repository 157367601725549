// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useMemo } from 'react'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {Object[]} props.options
 * @returns {JSX.Element}
 */
export default function RHFSelectMultiple({ name, options, ...other }) {
  const { control } = useFormContext()

  const valueLabelMap = useMemo(
    () =>
      options.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
    [options]
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Select
            {...field}
            value={field.value ?? []}
            select
            fullWidth
            multiple
            error={Boolean(error)}
            helperText={error?.message}
            renderValue={(selected) =>
              selected?.map((s) => valueLabelMap[s]).join(', ')
            }
            input={<TextField {...other} />}
          >
            {options.map(({ label, value }) => (
              <MenuItem
                key={`${name}-${label}`}
                value={value}
              >
                <Checkbox checked={Boolean(field.value?.includes(value))} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        )
      }}
    />
  )
}
