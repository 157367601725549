// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * React Hook Form wrapper for TextField
 * @param {Object} props
 * @param {string} props.name
 * @param {React.ReactNode} [props.helperText]
 * @returns {JSX.Element}
 */
export default function RHFTextField({ name, helperText, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === 'number'
              ? field.value.toString()
              : field.value
          }
          InputLabelProps={other.type === 'date' ? { shrink: true } : undefined}
          error={Boolean(error)}
          helperText={error?.message || helperText}
          {...other}
        />
      )}
    />
  )
}
