// react
import { useState } from 'react'
// @mui
import { AddOutlined, CloseOutlined, ImageOutlined } from '@mui/icons-material'
import { Box, ButtonBase, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
// forms
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
// components
import NativeImage from '~/components/NativeImage'
import { GridCarousel } from '~/components/carousel'
// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
// utils
import { get } from 'lodash'

// sibling RHF
import RHFImageUpload from '~/components/hook-form/RHFImageUpload'

// ----------------------------------------------------------------------

const ImageThumbnailContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '5rem',
  width: '5rem',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  overflow: 'hidden'
}))

const ImageThumbnail = styled(NativeImage, {
  shouldForwardProp: (prop) => prop !== 'isCurrent'
})(({ isCurrent }) => ({
  width: '100%',
  height: '100%',
  filter: isCurrent ? 'brightness(75%)' : 'none',
  objectFit: 'cover'
}))

const ImageThumbnailAction = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'disabled'
})(({ theme, disabled }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  opacity: 0.8,
  color: theme.palette.grey[500],
  transition: theme.transitions.create(['background-color', 'color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard
  }),
  '&:hover, &:active, &:focus': disabled
    ? {}
    : {
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.66)'
      }
}))

const ImageThumbnailIcon = styled('span')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%'
})

const ImageRemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '0.2rem',
  right: '0.2rem',
  zIndex: theme.zIndex.tooltip,
  padding: '0.2rem',
  backgroundColor: 'rgba(0,0,0,0.5)',
  color: theme.palette.grey[300],
  '&:hover, &:focus, &:active': { backgroundColor: theme.palette.primary.main },
  '& > *': { fontSize: '0.8rem' }
}))

const HelperText = styled('p')(({ theme }) => ({
  fontSize: 'small',
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary
}))

const ErrorText = styled('p')(({ theme }) => ({
  fontSize: 'small',
  marginTop: theme.spacing(1),
  color: theme.palette.error.main
}))

// ----------------------------------------------------------------------

/**
 * Custom component for uploading multiple images to Cloudflare Images
 * Integrated with React Hook Form
 * @param {Object} props
 * @param {string} props.name - name of the field
 * @param {number} props.maxImage - maximum number of images to upload
 * @param {(string|(i: number)=> string)} props.alt - alt text for the image, also accept function with signature `(imageNo): string
 * @param {React.ReactNode} props.helperText - helper text to display below the image upload`
 * @returns {JSX.Element}
 */
export default function RHFImageMultipleUpload({
  name,
  maxImage,
  alt,
  helperText,
  usage
}) {
  const { _ } = useLingui()

  // image fields
  const {
    fields: imageFields,
    append: appendImage,
    remove: removeImage
  } = useFieldArray({ name })

  const {
    getValues,
    formState: { errors }
  } = useFormContext()

  // image preview & thumbnails
  const [currentImageNo, setCurrentImageNo] = useState(0)
  const currentImages = useWatch({ name })
  const isAllImageFilled = !currentImages.some((image) => !image.imageURL)

  // action handlers
  const handleAddImage = () => {
    if (!isAllImageFilled) return

    if (!alt) {
      appendImage({ imageURL: '' })

      return
    }

    appendImage({
      imageURL: '',
      alt: typeof alt === 'function' ? alt(imageFields.length + 1) : alt
    })

    const updatedImages = getValues(name)
    setCurrentImageNo(updatedImages.length - 1)
  }

  const createImageThumbnailClickHandler = (imageNo) => () => {
    setCurrentImageNo(imageNo)
  }

  const createRemoveImageHandler = (imageNo) => {
    if (imageNo === 0) return null

    return () => {
      if (currentImageNo === imageNo) {
        setCurrentImageNo((c) => c - 1)
      }

      removeImage(imageNo)
    }
  }

  // field error message
  const fieldErrorMessage = get(errors, name)?.message

  return (
    <div>
      <Box sx={{ marginBottom: 2 }}>
        {imageFields.map((field, imageNo) => (
          <div key={field.id}>
            <Box
              sx={{ display: currentImageNo === imageNo ? 'block' : 'none' }}
            >
              <RHFImageUpload
                name={`${name}.${imageNo}.imageURL`}
                usage={usage}
              />
            </Box>
          </div>
        ))}
      </Box>

      <GridCarousel
        columns='auto'
        spacing={1}
      >
        {imageFields.map((field, imageNo) => (
          <ImageThumbnailContainer key={field.id}>
            {imageNo !== 0 && (
              <ImageRemoveButton onClick={createRemoveImageHandler(imageNo)}>
                <CloseOutlined />
              </ImageRemoveButton>
            )}

            <ImageThumbnailAction
              onClick={createImageThumbnailClickHandler(imageNo)}
            >
              {/* For some reason, currentImages value is updated 1 render late
                 in latest version of react-hook-form, so we add optional chaining
                 to prevent error when accessing currentImages[imageNo]?.XXX,
                 right after appendImage */}
              {currentImages[imageNo]?.imageURL ? (
                <ImageThumbnail
                  isCurrent={imageNo === currentImageNo}
                  src={currentImages[imageNo]?.imageURL}
                  alt={currentImages[imageNo]?.alt}
                />
              ) : (
                <ImageThumbnailIcon>
                  <ImageOutlined />
                </ImageThumbnailIcon>
              )}
            </ImageThumbnailAction>
          </ImageThumbnailContainer>
        ))}

        {imageFields.length < maxImage && (
          <Tooltip
            arrow
            title={
              !isAllImageFilled
                ? _(
                    msg`Mohon upload gambar jika ingin menambahkan lebih banyak gambar`
                  )
                : _(msg`Tambahkan Gambar`)
            }
          >
            <ImageThumbnailContainer>
              <ImageThumbnailAction
                onClick={handleAddImage}
                disabled={!isAllImageFilled}
              >
                <ImageThumbnailIcon>
                  <AddOutlined />
                </ImageThumbnailIcon>
              </ImageThumbnailAction>
            </ImageThumbnailContainer>
          </Tooltip>
        )}
      </GridCarousel>

      {fieldErrorMessage ? (
        <ErrorText>{fieldErrorMessage}</ErrorText>
      ) : (
        helperText && <HelperText>{helperText}</HelperText>
      )}
    </div>
  )
}
