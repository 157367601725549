// react
import { forwardRef } from 'react'
// editor
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  markdownShortcutPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  linkDialogPlugin,
  CreateLink,
  Separator,
  ListsToggle,
  BlockTypeSelect
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
// mui
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} [props.height]
 * @param {Function} [props.onChange]
 * @param {boolean} [props.isAllowLink]
 * @param {string} [props.initialValue]
 */
const Editor = forwardRef(
  (
    { height = '20rem', onChange, onBlur, isAllowLink = false, initialValue },
    ref = null
  ) => {
    return (
      <div>
        {/**
         * Styling override to fix link popover blocked by sidebar
         * somehow, sometimes .mdxedior-popup-container is not available
         * i.e. not applied to the popover parent component
         * so, we need to use other selector to target the popover
         */}
        <style
          jsx
          global
        >
          {`
            .mdxeditor-popup-container,
            body > [class*='_editorRoot_'],
            body > [class*='_popupContainer_'] > * {
              z-index: 1200;
            }
          `}
        </style>

        <Box
          border='1px solid'
          borderColor='divider'
          borderRadius={1}
          overflow='hidden'
        >
          <Box
            height={height}
            overflow='scroll'
            sx={{
              // fix list margin
              '& .mdxeditor li': {
                marginY: 0,
                marginInlineStart: 4
              },
              '& .mdxeditor li[role=checkbox]': {
                marginY: 0.5,
                marginInlineStart: 2
              },
              '& .mdxeditor li[role=checkbox]::before': {
                top: '5px'
              },
              // actual text input height inside editor to increase clickarea
              '& [role=textbox]': {
                minHeight: `calc(${height} - 48px)`
              },
              // HACKS: MDXEditor Custom Plugin API is still hard to understand
              // and doesn't have a good documentation anyway
              // so, we need to use some CSS hacks to disable feature that will not be used
              // 1. disable underline
              '& .mdxeditor div:has(> button[title=Underline])': {
                display: 'none'
              }
            }}
          >
            <MDXEditor
              onChange={onChange}
              onBlur={onBlur}
              markdown={initialValue ?? ''}
              plugins={[
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      <UndoRedo />
                      <Separator />
                      <BoldItalicUnderlineToggles />
                      <BlockTypeSelect />
                      <Separator />
                      {isAllowLink && (
                        <>
                          <CreateLink />
                          <Separator />
                        </>
                      )}
                      {/* Disable checklist list type */}
                      <Box
                        sx={{
                          display: 'contents',
                          '& [role=group] button:last-child': {
                            display: 'none'
                          }
                        }}
                      >
                        <ListsToggle />
                      </Box>
                    </>
                  )
                }),
                headingsPlugin(),
                listsPlugin(),
                markdownShortcutPlugin(),
                ...(isAllowLink ? [linkPlugin(), linkDialogPlugin()] : [])
              ]}
              ref={ref}
            />
          </Box>
        </Box>
      </div>
    )
  }
)

Editor.displayName = 'Editor'

export default Editor
