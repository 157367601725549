// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {React.ReactNode} props.helperText
 * @returns {JSX.Element}
 */
export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext()

  const render = ({ field, fieldState: { error } }) => (
    <FormControl
      required
      error={Boolean(error)}
      variant='standard'
    >
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value}
          />
        }
        {...other}
      />

      {(Boolean(error) || Boolean(helperText)) && (
        <FormHelperText>{error.message || helperText}</FormHelperText>
      )}
    </FormControl>
  )

  return (
    <Controller
      name={name}
      control={control}
      render={render}
    />
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {React.ReactNode} props.helperText
 * @param {Object[]} props.options
 * @returns {JSX.Element}
 */
export function RHFMultiCheckbox({ name, helperText, options, ...other }) {
  const { control } = useFormContext()

  const render = ({ field, fieldState: { error } }) => {
    const handleOptionChange = options.map((availableOption) => {
      const onSelected = (option) =>
        field.value.includes(option)
          ? field.value.filter((value) => value !== option)
          : [...field.value, option]

      return () => field.onChange(onSelected(availableOption.value))
    })

    return (
      <FormControl
        required
        error={Boolean(error)}
        variant='standard'
      >
        <FormGroup>
          {options.map((option, optionNo) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={field.value.includes(option.value)}
                  onChange={handleOptionChange[optionNo]}
                />
              }
              label={option.label}
              {...other}
            />
          ))}
        </FormGroup>

        {(Boolean(error) || Boolean(helperText)) && (
          <FormHelperText>{error.message || helperText}</FormHelperText>
        )}
      </FormControl>
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={render}
    />
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.value
 * @returns {JSX.Element}
 */
export function RHFMultiCheckboxDistributed({ name, value, ...other }) {
  const { control } = useFormContext()

  const render = ({ field }) => {
    const onSelected = (option) =>
      field.value.includes(option)
        ? field.value.filter((currentValue) => currentValue !== option)
        : [...field.value, option]

    const handleSelected = () => field.onChange(onSelected(value))

    return (
      <Checkbox
        {...field}
        checked={field.value.includes(value)}
        onChange={handleSelected} // skipcq: JS-0417
      />
    )
  }

  return (
    <FormControlLabel
      key={value}
      control={
        <Controller
          name={name}
          control={control}
          render={render}
        />
      }
      {...other}
    />
  )
}
