// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Switch,
  FormControlLabel,
  FormControl,
  FormHelperText
} from '@mui/material'

// ----------------------------------------------------------------------

/**
 * React Hook Form wrapper for Switch
 * @param {Object} props
 * @param {string} props.name
 * @param {React.ReactNode} [props.helperText]
 * @returns {JSX.Element}
 */
export default function RHFSwitch({ name, helperText, ...other }) {
  const { control } = useFormContext()

  const render = ({ field, fieldState: { error } }) => (
    <FormControl
      required
      error={Boolean(error)}
      variant='standard'
    >
      <FormControlLabel
        control={
          <Switch
            {...field}
            checked={field.value}
          />
        }
        {...other}
      />
      {(Boolean(error) || Boolean(helperText)) && (
        <FormHelperText>{error.message || helperText}</FormHelperText>
      )}
    </FormControl>
  )

  return (
    <Controller
      name={name}
      control={control}
      render={render}
    />
  )
}
