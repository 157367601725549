// form
import { useFormContext } from 'react-hook-form'

// ----------------------------------------------------------------------

/**
 * Auto clear afterSubmit error after other errors are resolved
 * Intentionally created as component instead of hook to isolate re-render
 * @returns {null}
 */
export default function AutoClearAfterSubmitError() {
  const {
    formState: { errors },
    clearErrors
  } = useFormContext()

  // Intentionally not wrapping this action is useEffect
  // since somehow useEffect does not watch the formState.errors properly

  // skip if no afterSubmit error
  if (errors.afterSubmit && errors.afterSubmit.type === 'validation') {
    const { afterSubmit, ...otherError } = errors

    // clear errors afterSubmit error after other errors are resolved
    if (Object.keys(otherError).length === 0) {
      clearErrors('afterSubmit')
    }
  }

  return null
}
