// form
import { FormProvider as Form } from 'react-hook-form'

// sub-components
import AutoClearAfterSubmitError from './AutoClearAfterSubmitError'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {()=> void} props.onSubmit
 * @param {Object} props.methods
 * @param {boolean} [props.isAutoClearAfterSubmitError=true]
 * @returns {JSX.Element}
 */
export default function FormProvider({
  children,
  onSubmit,
  methods,
  isAutoClearAfterSubmitError = true
}) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>

      {isAutoClearAfterSubmitError && <AutoClearAfterSubmitError />}
    </Form>
  )
}
