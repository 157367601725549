// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel
} from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {Object[]} props.options
 * @param {import('@mui/material').FormControlLabelProps} formControlLabelProps
 * @param {import('@mui/material').RadioProps} radioProps
 * @returns {JSX.Element}
 */
export default function RHFRadioGroup({
  name,
  options,
  formControlLabelProps,
  radioProps,
  ...other
}) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup
            {...field}
            row
            {...other}
          >
            {options.map((option) => (
              <FormControlLabel
                {...formControlLabelProps}
                key={option.value}
                value={option.value}
                control={<Radio {...radioProps} />}
                label={option.label}
              />
            ))}
          </RadioGroup>

          {Boolean(error) && (
            <FormHelperText error>{error.message}</FormHelperText>
          )}
        </div>
      )}
    />
  )
}
