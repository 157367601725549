// @mui
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import {
  InsertDriveFileOutlined,
  AudioFileOutlined,
  CheckCircleOutline
} from '@mui/icons-material'
// components
import TextMaxLine from '~/components/TextMaxLine'
// utils
import { formatDateTimeCompact } from '~/libs/dateHelper'

// ----------------------------------------------------------------------

const FILE_ICON_MAP = {
  audio: AudioFileOutlined,
  file: InsertDriveFileOutlined
}

/**
 * @param {Object} props
 * @param {'file'|'audio'} props.type
 * @param {string} props.fileName
 * @param {string} props.createdAt
 * @param {boolean} props.isSelected
 * @param {()=>void} props.onSelect
 * @returns {JSX.Element}
 */
export default function FileCard({
  type = 'file',
  fileName,
  createdAt,
  isSelected,
  onSelect
}) {
  const Icon = FILE_ICON_MAP[type]

  return (
    <Card
      variant='outlined'
      sx={{ borderColor: isSelected ? 'primary.main' : undefined }}
    >
      <CardActionArea onClick={onSelect}>
        <Box p={1.5}>
          <Stack
            direction='row'
            spacing={1}
            wrap='nowrap'
          >
            <Icon sx={{ position: 'relative', top: 3 }} />

            <Box sx={{ width: 'calc(100% - 4rem)' }}>
              <TextMaxLine line={1}>{fileName}</TextMaxLine>

              <Typography variant='shy'>
                Created at: <span>{formatDateTimeCompact(createdAt)}</span>
              </Typography>
            </Box>

            {isSelected && (
              <CheckCircleOutline
                sx={{ color: 'primary.main', fontSize: '1rem' }}
              />
            )}
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  )
}
