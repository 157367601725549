// form
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { isFinite } from 'lodash'
import { useFormContext, useController } from 'react-hook-form'
// utils
import { formatNumber, formatOrFallback } from '~/libs/numberFormatter'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.label
 * @param {string} props.name
 * @param {number} props.resetValue
 * @param {number} props.minValue
 * @param {number} props.maxValue
 * @param {number} props.step - must be a positive integer
 * @param {React.ReactNode} [props.helperText]
 * @param {boolean} [props.isDisabled]
 * @returns {JSX.Element}
 */
export default function RHFNumberIncrement({
  label,
  name,
  resetValue = 1,
  minValue,
  maxValue,
  step = 1,
  helperText,
  isDisabled
}) {
  const { control } = useFormContext()
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { error }
  } = useController({ name, control })

  // disable & enable the increment button based on the value
  const isAllowedToSubstract = isFinite(minValue)
    ? !isFinite(value) || value - step >= minValue
    : true
  const isAllowedToAdd = isFinite(maxValue)
    ? !isFinite(value) || value + step <= maxValue
    : true

  // never call this function directly from user interaction, always use wrapper functions
  // absoluteStep = step with sign, i.e. positive or negative
  const _handleIncrement = (currentValue, absoluteStep) => {
    // no need to do anything if the field is disabled
    if (isDisabled) return

    const parsedValue = parseInt(currentValue)
    if (!isFinite(parsedValue)) {
      onChange(resetValue)

      return
    }

    onChange(parsedValue + absoluteStep)

    // also trigger on blur for RHF validation trigger onTouched / onBlur
    // since this component does not have a native blur event
    onBlur()
  }

  const handleIncrementAdd = () => {
    if (!isAllowedToAdd) return

    _handleIncrement(value, step)
  }

  const handleIncrementSubtract = () => {
    if (!isAllowedToSubstract) return

    _handleIncrement(value, step * -1)
  }

  // tooltip
  const tooltipText = error ? error.message : helperText ?? null

  return (
    <div ref={ref}>
      <Tooltip
        title={tooltipText}
        arrow
      >
        <Grid
          container
          spacing={1.5}
          alignItems='center'
        >
          <Grid
            item
            xs
          >
            <Typography
              fontSize='small'
              color={
                isDisabled ? 'text.disabled' : error ? 'error' : 'text.primary'
              }
            >
              {label}
            </Typography>
          </Grid>

          <Grid
            item
            xs='auto'
          >
            <Stack
              direction='row'
              spacing={1.5}
              flexWrap='nowrap'
              alignItems='center'
            >
              <Button
                variant='outlined'
                onClick={handleIncrementSubtract}
                color='inherit'
                size='small'
                sx={{ minWidth: 'auto', width: '1.8rem' }}
                disabled={!isAllowedToSubstract || isDisabled}
              >
                -
              </Button>

              <Typography
                fontSize='large'
                fontWeight='bold'
                color={isDisabled ? 'text.disabled' : 'inherit'}
                sx={{ userSelect: 'none' }}
              >
                {formatOrFallback(value, formatNumber)}
              </Typography>

              <Button
                variant='outlined'
                onClick={handleIncrementAdd}
                color='inherit'
                size='small'
                sx={{ minWidth: 'auto', width: '1.8rem' }}
                disabled={!isAllowedToAdd || isDisabled}
              >
                +
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Tooltip>
    </div>
  )
}
