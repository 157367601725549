/**
 * @param {Blob} blobData
 * @param {string} filename
 * @returns {void}
 */
function triggerDownload(blobData, filename) {
  // just in case this accidentally called in server
  if (typeof document === 'undefined') return

  const href = URL.createObjectURL(blobData)

  // create "a" HTML element with href to file & click
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

/**
 * @param {string} url
 * @returns {void}
 */
function openInNewTab(url) {
  if (typeof window === 'undefined') return

  window.open(url, '_blank')
}

const browserHelper = {
  triggerDownload,
  openInNewTab
}

export default browserHelper
