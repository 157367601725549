// form
import { useFormContext, useController } from 'react-hook-form'
// @mui
import { Autocomplete, TextField } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {Object[]} props.options
 * @param {React.ReactNode} props.helperText
 * @param {Object} props.AutocompleteProps
 * @returns {JSX.Element}
 */
export default function RHFAutocomplete({
  name,
  options,
  helperText,
  AutocompleteProps,
  ...other
}) {
  const { control, setValue } = useFormContext()

  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const handleChange = (_, value) => {
    setValue(name, value)
  }

  return (
    <Autocomplete
      {...AutocompleteProps}
      options={options}
      value={field.value}
      onChange={handleChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, sx: { paddingY: '10px' } }}
            fullWidth
            error={Boolean(error)}
            helperText={error?.message || helperText}
            {...other}
          />
        )
      }}
    />
  )
}
