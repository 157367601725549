import { createSWRHook } from './_factory'
import { fileService } from '~/services'

export const useFileList = createSWRHook({
  key: 'file-list',
  fetcher: fileService.getList,
  withAuth: 'always'
})

export const useFile = createSWRHook({
  key: 'file',
  fetcher: fileService.accessFile,
  withAuth: 'always'
})
