// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { Slider } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * React Hook Form wrapper for Slider
 * @param {Object} props
 * @param {string} props.name
 * @returns {JSX.Element}
 */
export default function RHFSlider({ name, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Slider
          {...field}
          valueLabelDisplay='auto'
          {...other}
        />
      )}
    />
  )
}
