// @mui
import { FileUploadOutlined, TaskOutlined } from '@mui/icons-material'
import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

const DropZoneComponent = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '6rem',
  borderRadius: '1rem',
  border: '1px dashed',
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.neutral,
  transition: [
    theme.transitions.create('border-color', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest
    })
  ],
  '&:hover': {
    borderColor: theme.palette.primary.main
  }
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.isLoading
 * @param {boolean} props.isCurrentFileExist
 * @param {(e)=> void} props.onClick
 * @param {(err: Error)=>void} props.onError
 * @param {(f: File)=>void} props.onDrop
 * @returns {JSX.Element}
 */
export default function DropZone({
  isLoading,
  isCurrentFileExist,
  onClick,
  onDrop,
  onError
}) {
  const { _ } = useLingui()

  const handleDragOver = (e) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()
  }

  const handleDrop = (e) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()

    const dtInterfaceName = e.dataTransfer.items ? 'items' : 'files'
    const dtInterface = e.dataTransfer[dtInterfaceName]

    if (dtInterface.length > 1) {
      onError(new Error('Maximum one file is allowed'))

      return
    }

    if (dtInterfaceName === 'items' && dtInterface[0]?.kind !== 'file') {
      onError(new Error('Make sure you are dropping a valid file'))

      return
    }

    const file = e.dataTransfer.items[0].getAsFile()

    onDrop(file)
  }

  return (
    <DropZoneComponent
      onClick={onClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Box sx={{ textAlign: 'center' }}>
        {isLoading ? (
          <CircularProgress />
        ) : isCurrentFileExist ? (
          <TaskOutlined sx={{ color: 'primary.main' }} />
        ) : (
          <FileUploadOutlined />
        )}

        <br />

        <Typography
          variant='shy'
          className='text-center'
        >
          {isCurrentFileExist
            ? _(
                msg`Pilih file atau seret ke sini untuk mengganti file yang sudah ada...`
              )
            : _(msg`Pilih file atau seret ke sini...`)}
        </Typography>
      </Box>
    </DropZoneComponent>
  )
}
