// form
import { useFormContext, Controller } from 'react-hook-form'
// components
import { CloudflareImageUpload } from '~/components/cloudflare'

/**
 * React Hook Form wrapper for CloudflareImageUpload
 * @param {Object} props
 * @param {string} props.name
 * @returns {JSX.Element}
 */
export default function RHFCloudflareImageUpload({ name, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <CloudflareImageUpload
            error={error?.message}
            {...field}
            {...other}
          />
        )
      }}
    />
  )
}
