// react
import { forwardRef, useRef, useState } from 'react'
// @mui
import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material'
import { UploadFileOutlined } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'
import { styled } from '@mui/material/styles'
// hooks
import useAuth from '~/hooks/useAuth'
// components
import NativeImage from '~/components/NativeImage'
// services
import { imageService } from '~/services'
// i18n
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
// utils
import fileHelper from '~/libs/fileHelper'

// ----------------------------------------------------------------------

const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5MB

// ----------------------------------------------------------------------

const ImageContainer = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.grey[300],
  borderWidth: '1px',
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  overflow: 'hidden',
  cursor: 'pointer',
  '& > *': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
}))

const overlayBaseStyle = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  backgroundColor: 'rgba(0, 0, 0, 0.66)',
  '& *': {
    color: '#fff',
    fontSize: 'small !important',
    lineHeight: 1.15
  }
})

const LoadingOverlay = styled('div')(({ theme }) => ({
  ...overlayBaseStyle(theme),
  zIndex: 10
}))

const UploadOverlay = styled('div')(({ theme }) => ({
  ...overlayBaseStyle(theme),
  zIndex: 15,
  backgroundColor: 'transparent',
  opacity: 0.8,
  transition: theme.transitions.create(['background-color', 'color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard
  }),
  '& *': {
    color: theme.palette.grey[500]
  },
  '& p': {
    fontSize: 'small',
    lineHeight: 1.15
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.66)',
    '& *': { color: '#fff' }
  }
}))

const HelperText = styled('p')(({ isError, theme }) => ({
  fontSize: '0.7rem !important',
  marginTop: theme.spacing(0.5),
  opacity: 0.75,
  color: isError ? theme.palette.error.main : theme.palette.grey[700]
}))

// ----------------------------------------------------------------------

/**
 * Upload image to Clouflare Images
 * @param {Object} props
 * @param {(e)=>void} props.onChange - callback when image is changed
 * @param {string} props.label - label for the input field
 * @param {string} props.name - name for the input field
 * @param {string} props.value - current image value, can be CF image URL or other URL pointing to supported image
 * @param {string} props.error - error state
 * @param {React.ReactNode} props.helperText - helper text under the upload box
 * @param {Object} props.sx - MUI sx props, forwarded to the upload box wrapper element
 * @returns {JSX.Element}
 */
const ImageUpload = forwardRef(
  ({ usage, onChange, label, /* name */ value, error, helperText, sx }) => {
    // auth
    const { authorizeService } = useAuth()

    // i18n
    const { _ } = useLingui()

    // upload state
    const [isUploading, setIsUploading] = useState(false)
    const [uploadError, setUploadError] = useState(false)

    // hidden input field and value push-pull
    const [inputKey, setInputKey] = useState(`${usage}-${Date.now()}`)
    const inputRef = useRef(null)

    // common file uploader entrypoint
    const uploadFile = async (currentFile) => {
      setIsUploading(true)
      setUploadError(null)

      try {
        if (currentFile.size > MAX_IMAGE_SIZE) {
          setUploadError(
            _(
              msg`Ukuran gambar terlalu besar, maksimum ukuran gambar adalah 5MB.`
            )
          )

          return
        }

        const base64 = await fileHelper.fileInputToBase64(currentFile)
        const imagePath = await authorizeService(imageService.uploadImage)({
          image: base64,
          usage

          // ...(usage.startsWith('product-') && { product })
        }).then((res) => res?.data?.results?.path)

        if (imagePath) onChange(imagePath)
      } catch (err) {
        const message =
          err?.response?.data?.message ||
          _(
            msg`Terjadi kesalahan saat mengupload gambar, silahkan coba lagi dalam beberapa saat.`
          )

        setUploadError(message)
      } finally {
        setIsUploading(false)
        setInputKey(`${usage}-${Date.now()}`)
      }
    }

    // event handler
    const handleInputFileChange = (e) => {
      uploadFile(Array.from(e.target.files)[0])
    }

    const handleOpenFileSelector = () => {
      if (!inputRef.current) return

      inputRef.current.click()
    }

    // const handleClearButtonClick = () => {
    //   setIsUploading(false)
    //   setCurrentFile(null)
    //   onChange('')
    //   setInputKey(`${name}-${Date.now()}`)
    // }

    return (
      <div>
        <Box sx={{ height: '12rem', width: '100%', ...(sx ?? {}) }}>
          <ImageContainer onClick={handleOpenFileSelector}>
            {value && (
              <NativeImage
                sx={{ objectFit: 'cover', objectPosition: 'center' }}
                src={value}
                alt={label ? `${label} preview` : 'preview image'}
              />
            )}

            {isUploading ? (
              <LoadingOverlay>
                <div>
                  <CircularProgress size='1.5em' />
                  <Typography component='p'>
                    <Trans>Menggungah Gambar</Trans>
                  </Typography>
                </div>
              </LoadingOverlay>
            ) : (
              <UploadOverlay>
                <div>
                  <UploadFileOutlined fontSize='medium' />
                  <Typography component='p'>
                    {value
                      ? _(msg`Ganti Gambar/GIF`)
                      : _(msg`Upload Gambar/GIF`)}
                  </Typography>

                  {(error || uploadError || helperText) && (
                    <div>
                      <HelperText isError={error || uploadError}>
                        {error || uploadError || helperText}
                      </HelperText>
                    </div>
                  )}
                </div>
              </UploadOverlay>
            )}

            <Box sx={visuallyHidden}>
              <label htmlFor={inputKey}>{label}</label>

              <input
                id={inputKey}
                key={inputKey}
                ref={inputRef}
                sx={visuallyHidden}
                type='file'
                accept='.png, .jpg, .jpeg, .gif, .webp'
                onChange={handleInputFileChange}
              />
            </Box>
          </ImageContainer>
        </Box>
      </div>
    )
  }
)

ImageUpload.displayName = ImageUpload

export default ImageUpload
